import styled from "styled-components";
import Text from "./Text";
import Media from "../Styles/Media";

const H1 = styled(Text).attrs({
  as: "h1",
})`
  font-weight: 700;
  font-size: 55px;
  line-height: 1;

  ${Media.phone`
    font-size: 70px;
  `}
  ${Media.largePhone`
    font-size: 100px;
  `}

  ${Media.tablet`
    font-size: 130px;
  `}
`;

export default H1;
