const navbarHeight = 60;
const navbarHeightEm = `${navbarHeight / 16}em`;

const theme = {
  colors: {
    black: "#000",
    white: "#fff",
    gray: "#6e6b61",
    gray2: "#dcdbd6",
    gray3: "#f4f3f1",
    gray4: "#f5f2f0",
    gray5: "#cfcfcf",
    gray6: "#777574",
    gray7: "#ede9e8",
    gray8: "#6e6a62",
    red: "#eb1b1b",
    yellow: "#e0dc4e",
    red2: "#f6bab9",
    green: "#019901",
    purple: "#780b6a",
  },
  height: {
    navbar: navbarHeightEm,
  },
};

export default theme;
