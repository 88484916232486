import styled from "styled-components";
import Media from "../Styles/Media";

const Text = styled.p`
  font-size: 12px;
  ${Media.phone`
    font-size: 13px;
  `}
  ${Media.largePhone`
    font-size: 13px;
  `}
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  color: ${(props) =>
    props.red ? props.theme.colors.red : props.theme.colors.black};

  ${(props) => props.custom || ""}
`;

export default Text;
