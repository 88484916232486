import React, { Fragment, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import FullScreenBackground from "./FullscreenBackground";
import Nav from "./Nav";
import { pages } from "./pages";
import MainContent from "./MainContent";
import ProjectListWithImage from "./ProjectListWithImage";
import MainContentProject from "./MainContentProject";
import { projects } from "./projects";
import Project from "./Project";
import Loader from "./Loader";

const getPage = (path) => {
  switch (path) {
    case "services":
      return pages.services;
    case "about":
      return pages.about;
    case "contacts":
      return pages.contacts;
    default: {
      if (path === "") {
        return pages.projects;
      }

      const project = projects.find(
        (project) => project.url === `/${path.toLowerCase()}`
      );

      if (project) {
        return { ...project, project: true };
      }

      return { notFound: true };
    }
  }
};

const Content = () => {
  const [background, setBackground] = useState({ color: "white" });
  const [uploaded, setUploaded] = useState(false);
  const [hover, setHover] = useState(false);
  const { "*": path } = useParams();
  const page = getPage(path.toLowerCase());

  const clear = useCallback(() => {
    setHover(false);
    setBackground({ color: "white" });
  }, []);

  if (page.notFound) {
    return <div>404</div>;
  }

  return (
    <Fragment>
      {uploaded ? null : <Loader setUploaded={setUploaded} />}
      {page.project ? (
        <Project clear={clear} {...page} />
      ) : (
        <>
          <Nav desktop clear={clear} path={path} hover={hover} />
          <FullScreenBackground background={background} />
          {page.logo ? (
            <MainContentProject
              page={page}
              path={path}
              hover={hover}
              setBackground={setBackground}
              setHover={setHover}
              clear={clear}
            />
          ) : (
            <MainContent clear={clear} page={page} path={path} hover={hover} />
          )}
          {page.logo ? <ProjectListWithImage /> : null}
        </>
      )}
    </Fragment>
  );
};

export default Content;
