import React from "react";
import styled from "styled-components";
import Media from "./Styles/Media";

const Logo = ({ hover }) => {
  return <Svg hover={hover} />;
};

const Svg = styled.div`
  background-image: url("/logo/logo_paolaiotti_bianco.svg");
  width: 100%;
  height: 50px;
  margin-bottom: 6px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  ${Media.phone`
    height: 65px;
  `}
  ${Media.largePhone`
    height: 93px;
  `}

  ${Media.tablet`
    height: 150px;
    margin-bottom: 20px;
    background-image: url(${(props) =>
      !props.hover ? "/logo/logo_paolaiotti_nero.svg" : "/logo/logo_paolaiotti_bianco.svg"});
  `}
`;

export default Logo;
