import React from "react";
import styled from "styled-components";
import Flex from "./Ui/Flex";
import Media from "./Styles/Media";
import { useNavigate } from "react-router-dom";
import { projects } from "./projects";
import Text from "./Ui/Text";

const ProjectListWithImage = () => {
  const navigate = useNavigate();
  return (
    <Box col>
      {projects.map(({ url, image, name, by, campaign }) => (
        <BoxProject col onClick={() => navigate(url)} key={url}>
          <Image image={image} />
          <Flex col>
            <Text
              bold
              custom="font-size: 10px; text-transform: uppercase;"
            >{`${name}`}</Text>
          </Flex>
        </BoxProject>
      ))}
    </Box>
  );
};

const Image = styled.div`
  padding-top: 134.5%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.image});
  margin-bottom: 5px;
`;

const BoxProject = styled(Flex)`
  padding: 10px;
  width: 100%;
  cursor: pointer;
`;

const Box = styled(Flex)`
  ${Media.tablet`
    display: none;
  `}
  overflow: auto;
  z-index: 0;
`;

export default ProjectListWithImage;
