import { css } from "styled-components";

const sizes = {
  extraLarge: 1900,
  largeDesktop: 1500,
  mediumDesktop: 1170,
  desktop: 991,
  tablet: 767,
  largePhone: 550,
  phone: 380,
  tabletHeight: 700,
};

const Media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16;
  const what = label.indexOf("Height") >= 0 ? "height" : "width";
  accumulator[label] = (...args) => css`
    @media (min-${what}: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export default Media;
