export const projects = [
  {
    url: "/projects/yoox-net-a-porter_fw23-24_campaign",
    image: "/projects/yoox-net-a-porter_fw23-24_campaign/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_fw23-24_campaign/_cover.jpg",
    name: "YOOX Net-A-Porter FW23-24 Campaign",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/yoox-net-a-porter_fw23-24_campaign/2.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/3.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/4.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/5.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/6.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/7.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/8.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/9.jpg",
      "https://player.vimeo.com/video/1007834802?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479___https://player.vimeo.com/video/1007602812?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Hugo Lecrux",
  },
  {
    url: "/projects/hemeryne",
    image: "/projects/hemeryne/_cover_mobile.jpg",
    desktop: "/projects/hemeryne/_cover.jpg",
    name: "Hemeryne",
    field: "Beauty",
    services: "Identity",
    images: [
      "/projects/hemeryne/1.jpg",
      "/projects/hemeryne/2.jpg",
      "/projects/hemeryne/3.jpg",
      "/projects/hemeryne/4.jpg",
      "/projects/hemeryne/5.jpg",
      "/projects/hemeryne/6.jpg",
    ],
  },
  {
    url: "/projects/8_by_yoox_fw24-25_campaign",
    image: "/projects/8_by_yoox_fw24-25_campaign/_cover_mobile.jpg",
    desktop: "/projects/8_by_yoox_fw24-25_campaign/_cover.jpg",
    name: "8 by Yoox FW23-24 Campaign",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/8_by_yoox_fw24-25_campaign/2.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/3.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/4.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/5.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/6.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/7.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/8.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/9.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/10.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/11.jpg",
      "https://player.vimeo.com/video/1007838029?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479___https://player.vimeo.com/video/1007597023?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Teresa Ciocia",
  },
  {
    url: "/projects/liu_jo_fw22-23_editorial",
    image: "/projects/liu_jo_fw22-23_editorial/_cover_mobile.jpg",
    desktop: "/projects/liu_jo_fw22-23_editorial/_cover.jpg",
    name: "Liu Jo Edits",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/liu_jo_fw22-23_editorial/2.jpg",
      "/projects/liu_jo_fw22-23_editorial/1.jpg",
      "/projects/liu_jo_fw22-23_editorial/3.jpg",
      "/projects/liu_jo_fw22-23_editorial/5.jpg",
      "/projects/liu_jo_fw22-23_editorial/6.jpg",
      "/projects/liu_jo_fw22-23_editorial/7.jpg",
      "/projects/liu_jo_ss21_editorial/15.jpg",
      "/projects/liu_jo_denim/2.jpg",
      "/projects/liu_jo_denim/3.jpg",
      "/projects/liu_jo_denim/4.jpg",
      "/projects/liu_jo_denim/5.jpg",
      "/projects/liu_jo_denim/6.jpg",
      "/projects/liu_jo_ss21_editorial/2.jpg",
      "/projects/liu_jo_ss21_editorial/7.jpg",
      "/projects/liu_jo_ss21_editorial/8.jpg",
      "/projects/liu_jo_ss21_editorial/11.jpg",
      "/projects/liu_jo_ss21_editorial/12.jpg",
      "/projects/liu_jo_ss21_editorial/13.jpg",
      "/projects/liu_jo_ss21_editorial/16.jpg",
      "/projects/liu_jo_ss21_editorial/14.jpg",
      "/projects/liu_jo_ss21_editorial/6.jpg",
      "/projects/liu_jo_ss21_editorial/9.jpg",
      "/projects/liu_jo_ss21_editorial/10.jpg",
      "/projects/liu_jo_ss21_editorial/5.jpg",
      "/projects/liu_jo_ss21_editorial/3.jpg",
      "/projects/liu_jo_ss21_editorial/4.jpg",
    ],
    credits: "Photography by Raffaele Cerulo, Matteo Montanari, Alvaro Beamud Cortés <br> Creative collaboration with Francesco Roncaglia",
  },
  {
    url: "/projects/hemeryne_edit",
    image: "/projects/hemeryne_edit/_cover_mobile.jpg",
    desktop: "/projects/hemeryne_edit/_cover.jpg",
    name: "Hemeryne Edit",
    field: "Beauty",
    services: "Art Direction",
    images: [
      "/projects/hemeryne_edit/1.jpg",
      "/projects/hemeryne_edit/2.jpg",
      "/projects/hemeryne_edit/3.jpg",
      "/projects/hemeryne_edit/4.jpg",
      "/projects/hemeryne_edit/5.jpg",
      "/projects/hemeryne_edit/6.jpg",
    ],
  },
  {
    url: "/projects/liu_jo_fragrance",
    image: "/projects/liu_jo_fragrance/_cover_mobile.jpg",
    desktop: "/projects/liu_jo_fragrance/_cover.jpg",
    name: "Liu Jo Fragrance Campaign",
    field: "Fashion",
    services: "Creative Direction",
    images: [
      "/projects/liu_jo_fragrance/2.jpg",
      "/projects/liu_jo_fragrance/3.jpg",
      "/projects/liu_jo_fragrance/4.jpg",
      "/projects/liu_jo_fragrance/6.jpg",
      "/projects/liu_jo_fragrance/7.jpg",
      "/projects/liu_jo_fragrance/8.png",
      "https://player.vimeo.com/video/1008359429?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/671662033?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/671695150?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Jacopo Noera <br> Film Direction by Popkorn films",
  },
  {
    url: "/projects/yoox-net-a-porter_fw23-24_editorial",
    image: "/projects/yoox-net-a-porter_fw23-24_editorial/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_fw23-24_editorial/_cover.jpg",
    name: "YOOX Net-A-Porter Edits",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/yoox-net-a-porter_fw23-24_editorial/7.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/8.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/9.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/10.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/11.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/12.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/17.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/18.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/19.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/1.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/2.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/3.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/4.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/5.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/6.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/14.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/15.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/16.jpg",
    ],
    credits: "Photography by Alessio Keilty, Jorge Fuster, Hugo Lecrux, Ben Parks",
  },
  {
    url: "/projects/doweedo",
    image: "/projects/doweedo/_cover_mobile.jpg",
    desktop: "/projects/doweedo/_cover.jpg",
    name: "Doweedo",
    field: "Lifestyle",
    services: "Identity",
    images: [
      "/projects/doweedo/1.png",
      "/projects/doweedo/2.png",
      "/projects/doweedo/3.png",
      "/projects/doweedo/4.jpg",
      "/projects/doweedo/6.gif",
    ],
  },
  {
    url: "/projects/rebelqueen_fw19-20_editorial",
    image: "/projects/rebelqueen_fw19-20_editorial/_cover_mobile.jpg",
    desktop: "/projects/rebelqueen_fw19-20_editorial/_cover.jpg",
    name: "Rebelqueen",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/rebelqueen_fw19-20_editorial/9.jpg",
      "/projects/rebelqueen_fw19-20_editorial/10.jpg",
      "/projects/rebelqueen_fw19-20_editorial/11.jpg",
      "/projects/rebelqueen_fw19-20_editorial/13.jpg",
      "/projects/rebelqueen_fw19-20_editorial/14.jpg",
      "/projects/rebelqueen_fw19-20_editorial/15.jpg",
      "/projects/rebelqueen_fw19-20_editorial/16.jpg",
      "/projects/rebelqueen_fw19-20_editorial/17.jpg",
      "/projects/rebelqueen_fw19-20_editorial/18.png",
      "/projects/rebelqueen_fw19-20_editorial/2.jpg",
      "/projects/rebelqueen_fw19-20_editorial/3.jpg",
      "/projects/rebelqueen_fw19-20_editorial/4.jpg",
      "/projects/rebelqueen_fw19-20_editorial/5.jpg",
      "/projects/rebelqueen_fw19-20_editorial/6.jpg",
      "/projects/rebelqueen_fw19-20_editorial/7.jpg",
      "/projects/rebelqueen_fw19-20_editorial/8.jpg",
    ],
    credits: "Photography Federico Sorrentino <br> Creative collaboration with Francesco Roncaglia",
  },
  {
    url: "/projects/yoox-net-a-porter_x_chris_glass",
    image: "/projects/yoox-net-a-porter_x_chris_glass/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_x_chris_glass/_cover.jpg",
    name: "Chris Glass x Yoox Net-a-Porter",
    field: "Design",
    services: "Art Direction",
    images: [
      "/projects/yoox-net-a-porter_x_chris_glass/1.jpg",
      "/projects/yoox-net-a-porter_x_chris_glass/2.jpg",
      "/projects/yoox-net-a-porter_x_chris_glass/3.jpg",
      "/projects/yoox-net-a-porter_x_chris_glass/4.jpg",
      "/projects/yoox-net-a-porter_x_chris_glass/5.jpg",
      "/projects/yoox-net-a-porter_x_chris_glass/6.jpg",
      "https://player.vimeo.com/video/1007603399?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Alessio Keilty",
  },
  {
    url: "/projects/sowe",
    image: "/projects/sowe/_cover_mobile.jpg",
    desktop: "/projects/sowe/_cover.jpg",
    name: "Sowe",
    field: "Fashion",
    services: "Identity & Product Design",
    images: [
      "/projects/sowe/1.jpg",
      "/projects/sowe/2.jpg",
      "/projects/sowe/3.jpg",
      "/projects/sowe/4.jpg",
      "/projects/sowe/4-1.jpg",
      "/projects/sowe/5.jpg",
      "/projects/sowe/6.jpg",
      "/projects/sowe/7.png",
      "/projects/sowe/7.gif",
    ], 
  },
  {
    url: "/projects/yoox-net-a-porter_superstar",
    image: "/projects/yoox-net-a-porter_superstar/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_superstar/_cover.jpg",
    name: "YOOX Net-A-Porter Superstar",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/yoox-net-a-porter_superstar/1.jpg",
      "/projects/yoox-net-a-porter_superstar/2.jpg",
      "/projects/yoox-net-a-porter_superstar/3.jpg",
      "/projects/yoox-net-a-porter_superstar/4.jpg",
      "/projects/yoox-net-a-porter_superstar/5.jpg",
      "/projects/yoox-net-a-porter_superstar/6.jpg",
      "/projects/yoox-net-a-porter_superstar/7.jpg",
    ],
    credits: "Illustration by Sara Andreasson",
  },
];
