import styled from "styled-components";

const Flex = styled.div`
  position: relative;
  display: flex;

  justify-content: ${(props) =>
    props.right
      ? "flex-end"
      : props.center
      ? "center"
      : props.between
      ? "space-between"
      : props.around
      ? "space-around"
      : "flex-start"};
  align-items: ${(props) =>
    props.centerItems ? "center" : props.endItems ? "flex-end" : "flex-start"};

  flex-direction: ${(props) => (props.col ? "column" : "row")};
  width: ${(props) => (props.fullW ? "100%" : "auto")};

  ${(props) => (props.pointer ? "cursor: pointer; p {cursor: pointer;}" : "")}

  ${(props) => props.custom || ""}
`;

export default Flex;
