import React from "react";
import styled from "styled-components";
import Media from "./Styles/Media";

const FullScreenBackground = ({ background }) => {
  const { color, image } = background;
  return <Background color={color} image={image} />;
};

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.color};
  ${(props) => (props.image ? `background-image: url(${props.image});` : "")}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  display: none;
  ${Media.tablet`
    display: block;
  `}
`;

export default FullScreenBackground;
