import React from "react";
import Flex from "./Ui/Flex";
import Text from "./Ui/Text";

const Contacts = () => {
  return (
    <Flex
      between
      centerItems
      custom="max-width: 490px; margin: 0 auto; width: 100%;"
    >
      <Flex col custom="display: grid; grid-template-rows: 1fr 1f 1fr;">
        <Text bold custom="text-align: left;">
          INFO AT
        </Text>
        <Text bold custom="text-align: left;">
          INSTAGRAM
        </Text>
      </Flex>
      <Flex col endItems>
        <a href="mailto:hello@sensai.to">
          <Text bold custom="text-align: right;">
            HELLO@PAOLAIOTTI.COM
          </Text>
        </a>
        <a href="https://www.instagram.com/paolinaiotti/">
        <Text bold custom="text-align: right;">
            @PAOLAIOTTI
          </Text>
        </a>
      </Flex>
    </Flex>
  );
};

export default Contacts;
