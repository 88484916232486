import Contacts from "./Contacts";
import ProjectList from "./ProjectList";
import Services from "./Services";

export const pages = {
  projects: {
    logo: true,
    BottomComponent: ProjectList,
  },
  about: {
    mobileTopDescription: true,
    description:
      "CREATIVE DIRECTOR, BRAND DESIGNER, AND CONTENT STRATEGIST WITH 10 YEARS OF EXPERIENCE IN THE LUXURY SECTOR. KNOWN FOR DEVELOPING CREATIVE CONCEPTS AND PRODUCING COHESIVE CONTENT ACROSS DIVERSE PLATFORMS, SHE BRINGS EXPERTISE IN BRAND DESIGN, PACKAGING AND PRINT EDITION, WEB DESIGN, AND SOCIAL DESIGN. HER DEEP UNDERSTANDING OF PRODUCTION SYSTEMS, PAIRED WITH HER ROLE AS A CONTENT AND ART MANAGER, HAS ENABLED HER TO LEAD VISUAL PROJECTS FOR INTERNATIONAL BRANDS, BOTH OFFLINE AND ONLINE. WITH A PARTICULAR FOCUS ON UI/UX, SHE COMBINES STRATEGIC INSIGHT AND A REFINED AESTHETIC TO ELEVATE EACH INITIATIVE. CO-FOUNDER OF SENSAI STUDIO, A LIFESTYLE, TRAVEL, AND ARCHITECTURE PROJECT DEDICATED TO CREATIVE DIRECTION, CONTENT CREATION FOR BRANDS AND MAGAZINES, AND BRAND STRATEGY FOR PLAYERS IN THE HOSPITALITY SECTOR.",
  },
  contacts: {
    BottomComponent: Contacts,
    desktopMiddleBottomComponent: true,
  },
  services: {
    mobileTopDescription: true,
    description:  
  "CREATIVE & ART DIRECTION / CONTENTS CREATION / FILM DIRECTION / BRAND STRATEGY / BRAND DESIGN / WEBSITE AND E-COMMERCE / PACKAGING / EDITORIAL DESIGN / SOCIAL DESIGN ANDSTRATEGY / EMAIL DESIGN AND STRATEGY",
 },
};
