import { createGlobalStyle } from "styled-components";
import Media from "./Media";

const MainStyle = createGlobalStyle`

  @font-face {
    font-family: "NeueHelveticaPro77CondensedBold";
    src: url('/fonts/MyWebfontsKit/webFonts/NeueHelveticaPro77CondensedBold/font.woff2') format('woff2'), url('/fonts/MyWebfontsKit/webFonts/NeueHelveticaPro77CondensedBold/font.woff') format('woff');
  }
  
  * {
    box-sizing: border-box;
  }

  a {
    display: block;
  }

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 13px;
    background-color: ${(props) => props.theme.colors.white};
    ${Media.phone`
      font-size: 13px;
    `}
  }

  body {
    overflow-x: hidden;

    .f {
      display: flex;
    }

    .f-c {
      flex-direction: column;
    }

    .j-c {
      justify-content: center;
    }

    .j-s-b {
      justify-content: space-between;
    }
    .j-s-a {
      justify-content: space-space-around;
    }

    .j-e {
      justify-content: flex-end;
    }

    .a-c {
      align-items: center;
    }

    .a-e {
      align-items: flex-end;
    }

    a {
      text-decoration: none;
      cursor: pointer;
      color: black;
    }

    .t-center {
      text-align: center;
    }

    .big {
      font-size: 1.4em;
    }
    .big-p {
      padding: 0.8em;
    }
  }

  .StripeElement {
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
    padding: 10px 0;
  }


  table {
    width:  100%;
  }

  

  h1, h2, h3, h4, p, textarea, input, button, abbr, select, li, span, div {
    margin: 0;
    font-family:${props => props.isApple ? "HelveticaNeue-CondensedBold, NeueHelveticaPro77CondensedBold,  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;" : "NeueHelveticaPro77CondensedBold, HelveticaNeue-CondensedBold,  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;" } 
    white-space: pre-line;
    max-width: 100%;
  }

  select::-ms-expand {
    display: none;
  }
  option {
    background-color: white;
    color: black;
  }
`;

export default MainStyle;
