import { ThemeProvider } from "styled-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import theme from "./Ui/theme";
import MainStyle from "./Styles/MainStyle";
import Content from "./Content";

const router = createBrowserRouter([
  {
    path: "*",
    element: <Content />,
  },
]);
function isAppleDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Controllo per i dispositivi Apple
  if (/iPhone|iPad|iPod|Macintosh/i.test(userAgent)) {
      return true;
  }
  return false;
}


function App() {
  console.log(isAppleDevice())
  return (
    <ThemeProvider theme={theme}>
      <MainStyle isApple={isAppleDevice()} />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
